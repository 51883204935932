import { utils } from '../utils/utils';

let isPopup = false;

export const setAppLaunchType = async () => {
  const host = utils.getHost();
  const memstorage = host?.scriptingObjects?.memstorage;
  isPopup = memstorage && (await memstorage?.get('isPopup'));
};

export const getAppLaunchType = () => isPopup;
