const currentEnv = () => {
  if (__env.domain && window.location.hostname !== 'localhost') {
    if (__env.domain.indexOf('QA') != -1) {
      return 'qa';
    }
    return __env.domain.toLowerCase();
  }
  return 'dev';
};

export function setUpLogger() {
  // logger
  const { logger, http, logUnhandledErrors, webvitals } = window.emuiDiagnostics || {};
  const instanceId = JSON.parse(sessionStorage.getItem('user')).encompass_instance_id;
  const userId = JSON.parse(sessionStorage.getItem('user')).user_name;
  if (logger) {
    window.emui.logger = logger({
      transport: http(window.__env.diagnosticsServiceUrl),
      index: 'encw',
      environment: currentEnv(),
      team: 'encw-leia',
      logger: 'icemt.encompassweb.logger',
      appName: 'Encompass Web Application',
      appVersion: window.emui.version,
      instanceId: instanceId,
      userId: userId
    });
    webvitals(window.emui.logger);
    logUnhandledErrors(window.emui.logger);
    window.emui.logger.info('Encompass web application initialized');
  } else console.warn('diagnostics library missing'); // eslint-disable-line no-console
}

export async function setUpBrum() {
  const baseURI = document.baseURI;
  window.emui = window.emui || {};
  window.emui.brumConfig = {
    appKey: window.__env.appDKey,
    beaconUrlHttp: window.__env.appDBeaconUrlHttp,
    beaconUrlHttps: window.__env.appDBeaconUrlHttps,
    adrumExtUrlHttp: new URL('public/js/vendor', baseURI).href,
    adrumExtUrlHttps: new URL('public/js/vendor', baseURI).href,
    customData: function() {
      return {
        userData: {
          'sub-app-id': 'LOConnect',
          device: __env.device,
          env: currentEnv()
        }
      };
    }
  };

  await import('@elliemae/pui-user-monitoring');
}
