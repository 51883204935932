const noop = () => {};

function loadScript(url, async) {
  return new Promise(resolve => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
    if (async) script.async = async;
    script.onload = script.onreadystatechange = function() {
      var readyState = script.readyState;
      if (!readyState || /complete|loaded/.test(script.readyState)) {
        script.onload = null;
        script.onreadystatechange = null;
        resolve();
      }
    };
  });
}

function removeScript(srcUrl) {
  const element = document.querySelectorAll('script');

  element.forEach(ele => {
    if (ele.src && ele.src.indexOf(srcUrl) != -1) {
      ele.remove();
    }
  });
}

export { loadScript, removeScript };
