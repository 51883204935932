/**
 * Derived Environemt Variables...
 * NOTE: Since these are derived variables, we should ideally put them in app file rather than in env file
 */

window.__env.evpTransacationUrl = `${window.__env['apiHost']}/vendor/v2`;
window.__env.apiUrl = `${window.__env['apiHost']}/encompass-cg/v2`;
window.__env.encPlatUrl = `${window.__env['apiHost']}/encompass-cg/`;
window.__env.ebsAPIUrl = `${window.__env['apiHost']}/encompass/v1`;
window.__env.ecsAPIUrl = `${window.__env['apiHost']}/ecs/v1`;
window.__env.epcAPIUrl = `${window.__env['apiHost']}/services/v1`;
window.__env.milestoneAPIUrl = `${window.__env['apiHost']}/encompass/`;
window.__env.workflowAPIUrl = `${window.__env['apiHost']}/workflow/v1`;
window.__env.eDeliveryAPIUrl = `${window.__env['apiHost']}/ellisign/v2`;
window.__env.eDeliveryV3APIUrl = `${window.__env['apiHost']}/ellisign/v3`;
window.__env.assetsApiUrl = `${window.__env['apiHost']}/content/v1`;
window.__env.adminBaseUrl = 'http://encompass-eb.qa.dco.elmae:11001/v2';
window.__env.customToolBaseUrl = `${window.__env['apiHost']}/encompass/v1/settings/extensions/customTools`;
window.__env.customFormsBaseUrl = 'https://api-dev.fsgenerator.rd.elliemae.io';
window.__env.qaApiUrl = 'http://encompass-eb.qa.dco.elmae:11001/v2';
window.__env.twoFactorVerifyTokenUrl = `${window.__env['apiHost']}/oauth2/v1/token`;
window.__env.osbresturl_perf = 'https://encompass-perfapib.elliemae.com/v2';
window.__env.apiV3Url = `${window.__env['apiHost']}/encompass/v3`;
window.__env.ebsAPIV3Url = `${window.__env['apiHost']}/encompass/v3`;
window.__env.loanSummary = `${window.__env['customFormsBaseUrl']}/settings/loan-summary`;
window.__env.loanSummaryProfile = `${window.__env['customFormsBaseUrl']}/settings/loan-summary/profile`;

window.__env.device;
window.__env.rdMatches;
window.__env.notificationsApi = `${window.__env['apiHost']}/streams`;
window.__env.instanceServicesRoute = 'encservices/v1/instancesSelector?orderType=AUTOMATED&instanceId';

/**
 * Host Adaptor URL
 */
window.__env.epcHostAdapter = `${window.__env['epcHAURL']}/host-adaptor/`;

window.__env.walkMeUrl =
  'https://cdn.walkme.com/users/897bcebc13fd48038c790e2866e6a9fb/test/walkme_897bcebc13fd48038c790e2866e6a9fb_https.js';
window.__env.helpUrlNG = 'https://help.elliemae.com/documentation/encompass-ng/Content/encompass-ng/';
window.__env.helpUrl = 'https://help.icemortgagetechnology.com/documentation/wenc/Content/wenc/';
window.__env.helpUrlTools = 'https://help.elliemae.com/documentation/encompass-ng/Content/encompass-ng/tools/';
window.__env.helpUrl2015 = 'https://help.elliemae.com/documentation/lo-connect/Content/lo-connect/';
window.__env.helpUrl2015FeeDetail =
  'https://help.icemortgagetechnology.com/documentation/wenc/Content/wenc/forms/Fee_Details.htm';
window.__env.videoTutorials =
  'https://help.elliemae.com/documentation/lo-connect/Content/lo-connect/library/tutorials.htm';
window.__env.onlineHelp = 'https://help.elliemae.com/documentation/lo-connect/Content/lo-connect/start/start.htm';
window.__env.affordableHousingMFILink = 'https://www.ffiec.gov/censusproducts.htm';
window.__env.businessApiUrl = `${window.__env['apiHost']}/encompass/v1`;
window.__env.businessApiUrlOld = `${window.__env['apiHost']}/encompass/v0.9`;
window.__env.borrowerContactsApiUrl = `${window.__env['apiHost']}/encompass/v1`;
window.__env.edsAPI = `${window.__env['apiHost']}/eds/v1/`;
// Application Landing Page
window.__env.landing = '/login';
// Default Application State
window.__env.defaultState = '/pipeline.all';
window.__env.defaultState_mobile = '/pipeline';
window.__env.roleusers = '/rules/loan/roleusers?roleid=';
window.__env.milestoneSettingsUrl = 'settingsLC/milestones';
// base url for assets requests
window.__env.baseHref = document.getElementsByTagName('base')[0] ? document.getElementsByTagName('base')[0].href : '/';
// url to use for pingId redirect so we can validate the auth token before pipeline calls
window.__env.twoFactorLanding = '/home';
// Whether or not to enable debug mode.. Setting this to false will disable console output
window.__env.enableDebug = true;

// How often to ping the API if there's no other activity happening
window.__env.apiKeepAliveInterval = 5 * 60 * 1000; // five minutes

//How often to ping the v3 workspace API if there is no other activity happening
window.__env.wsAPIKeepAliveInterval = 5 * 60 * 1000;

// use json files from local api folder when needed
window.__env.useLocalData = false;
window.__env.cmsApiUrl = `${window.__env['apiHost']}/content/v1/`;

window.__env.getDocumentsUrlExtn = '/loans/documents?loanId=';
window.__env.getDocumentsTemplateUrlExtn = '/loans/documents/template';
window.__env.getUnassignedAttachmentsUrlExtn = '/loans/attachments?IncludeRemoved=false&LoanId=';

// LEFT NAV URl
window.__env.LoanLeftNavUrl = `/loan-app/loan-left-nav`;

// LOAN SUMMARY BAR URL
window.__env.LoanSummaryBarUrl = `/loan-app/loan-summary`;

// OAPI URLs
window.__env.LOGIN_URL = `${window.__env['idpHost']}/`;
window.__env.LOGOUT_URL = `${window.__env['idpHost']}/ext/logout/loc`;
window.__env.twoFactorUrl = `${window.__env['idpHost']}/authorize`;

// OAPI API URLs
window.__env.OAPI_URL = `${window.__env['apiHost']}/oauth2/v1/token`;
window.__env.LOGOUT_REVOKE = `${window.__env['apiHost']}/oauth2/v1/token/revocation`;

//Evaluator API URL
window.__env.Evaluator_URL = `${window.__env['apiHost']}/encservices/v1/evaluators/serviceSetups`;

window.__env.EM_CLIENT_ID = 'tirykwfo';
window.__env.EM_RESPONSE_TYPE = 'code';
window.__env.EM_SCOPE = 'loc';
window.__env.LOAN_SOURCE = 'LOConnect';
window.ENC_CONSTANTS = {
  ENV: 'DEV',
  OSBRESTURL_PERF: 'https://encompass-perfapib.elliemae.com/v2',
  OSBRESTURL: window.__env.apiUrl,
  FORMAT: 'json',
  JSON_CONTENT_TYPE: 'application/json; charset=utf-8',
  ENTITIESTOFETCH_DMS:
    'Loan,Contact,ClosingEntity,Application,Borrower,RateLock,Liability,Residence,Funding,Loan,DownPayment,' +
    'LoanProductData,LoanSubmission,Mcaw,Property,RegulationZ,RegulationZPayment,TPO,UnderwriterSummary,Asset,ReoProperty,Employment,Income,' +
    'PurchaseCredit,MilestoneTaskContact,ClosingDocument,ClosingDisclosure1,ClosingCost,ElliLOCompensation,ATRQMBorower,Miscellaneous,LoanEstimate1,LoanEstimate2,LoanEstimate3,Gfe2010,Gfe2010Fees,Gfe2010Page,Gfe2010Section,Fee,LoanEstimate2,ServicingDisclosure,ClosingDisclosure4,FieldLockData,LoanAssociate,RespaHudDetail,Tsum,ClosingDisclosure3,ClosingDisclosure5,UCDDetail,FhaVaLoan,PrepaymentPenalty,Buydown,ElliUCDFields,FeeVariance,FeeVarianceOther,ClosingDisclosure2,Hud1Es,Hmda,LogAlert,LogComment,LogEntryLog,LogRecord,FreddieMac,AUSTrackingLog,NetTangibleBenefit,DisclosureNotices',
  ENTITIESTOFETCH_AE: 'AlertData',
  ENTITIESTOFETCH:
    'Loan,Contact,DocumentLog,MilestoneLog,ConversationLog,MilestoneTaskLog,MilestoneTaskContact,ClosingEntity,Application,Borrower,RateLock,Liability,Residence,Funding,Loan,DownPayment,' +
    'LoanProductData,LoanSubmission,Mcaw,Property,RegulationZ,RegulationZPayment,TPO,UnderwriterSummary,Asset,ReoProperty,Employment,Income,' +
    'PurchaseCredit,ClosingDocument,VaLoanData,ClosingDisclosure1,ClosingCost,ElliLOCompensation,ATRQMBorrower,LoanActionLog,FundingFee,LockRequestLog,LogSnapshotField,Miscellaneous,LoanEstimate1,LoanEstimate2,LoanEstimate3,' +
    'Gfe,Gfe2010,Gfe2010Fee,Gfe2010Page,Gfe2010Section,Fee,LoanEstimate2,ServicingDisclosure,ClosingDisclosure4,FieldLockData,RespaHudDetail,Tsum,ClosingDisclosure3,ClosingDisclosure5,UCDDetail,FhaVaLoan,DisclosureTracking2015Log,PrepaymentPenalty,Buydown,ElliUCDFields,FeeVariance,FeeVarianceOther,ClosingDisclosure2,Hud1Es,CrmLog,LoanAssociate,Hmda,LogAlert,LogComment,LogEntryLog,LogRecord,FreddieMac,AUSTrackingLog,NetTangibleBenefit,DisclosureNotices',
  HTTP_STATUS_CODE: {
    STATUS_CODE_500: 500,
    STATUS_CODE_409: 409,
    STATUS_CODE_405: 405,
    STATUS_CODE_415: 415,
    STATUS_CODE_404: 404,
    STATUS_CODE_401: 401,
    STATUS_CODE_200: 200,
    STATUS_CODE_201: 201,
    STATUS_CODE_204: 204,
    STATUS_CODE_304: 304
  },
  HTTP_VERB: {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE'
  },
  LOAN_ENTITIES: {
    BORROWER: 'Borrower',
    COBORROWER: 'Coborrower',
    ASSETS: 'Asset',
    LOAN: 'Loan'
  },
  SESSION_PATH: '/auth/sessions/',
  LOAN_PATH: '/loans/',
  All_STATES: '/states.json',
  DOMAINMODELSCHEMA_BY_ENTITIES: '/settings/loan/schema/entities?Entities='
};

/*
 * Angular hack to make sure our production builds work properly with the
 * <base href='...'> in the <head>
 *
 * https://github.com/angular/angular.js/issues/11091
 */

// temporarily disabling this to unblock QA for ed1 redirect blocker
var nativeGetElementsByTagName = document.getElementsByTagName;
document.getElementsByTagName = function(query) {
  return query !== 'base' ? nativeGetElementsByTagName.apply(this, arguments) : [];
};
