// https://github.com/webpack/style-loader/pull/96#issuecomment-228370602
import { login, fetch as fetchENCW, session, logout } from '@elliemae/encw-commons';
import { isEmpty } from 'lodash';
import { Lib, Enums } from '@elliemae/encw-core';
import { utils } from './../common/utils/utils';
import { loadScript, removeScript } from './loadScript';
import { VERSION } from './version';
import { setAppLaunchType } from '../common/services/app-launch-type';

if (!Lib.Configure.IsConfigurationDone) {
  Lib.Configure.initialize(Enums.logType.error);
}
window.globalShell = document.referrer.includes('optimalblue.com')
  ? false
  : sessionStorage.getItem('global-shell-enabled');
require('./env.derived');
import { setUpBrum, setUpLogger } from './appInit';

const noop = () => {};
console.json = () => {};
require('./pwa');

/* global WEBPACK_HOT */
require('./feature-toggle');

function fetchOptions() {
  if (window.globalShell) {
    return { headers: { Authorization: sessionStorage.getItem('Authorization') } };
  }
  return {};
}

function loadDefaultApplication() {
  console.log('loading application using loadDefaultApplication');
  const device = checkDevice();
  __env.device = device;
  // loan appropriate styles and application modules when device is determined.
  if (device === 'mobile') {
    const mobileStyles = new Promise((resolve, reject) => {
      require.ensure(
        [],
        require => {
          require('./vendor.mobile.scss');
          require('./app.mobile.scss');
          resolve();
        },
        reject,
        'mobile.styles'
      );
    });
    const mobileScripts = new Promise((resolve, reject) => {
      require.ensure(
        [],
        require => {
          require('./app.mobile.module');
          resolve();
        },
        reject,
        'mobile.scripts'
      );
    });
    return Promise.all([mobileScripts, mobileStyles]);
  } else {
    const webStyles = new Promise((resolve, reject) => {
      require.ensure(
        [],
        require => {
          require('./vendor.web.scss');
          require('./app.web.scss');
          resolve();
        },
        reject,
        'web.styles'
      );
    });
    const webScripts = new Promise((resolve, reject) => {
      require.ensure(
        [],
        require => {
          require('./app.web.module');
          resolve();
        },
        reject,
        'web.scripts'
      );
    });
    return Promise.all([webScripts, webStyles]);
  }
}
async function loadApplication(ebsVersion, hostAppArgs = {}) {
  await setAppLaunchType();
  session.set('ebsVersion', ebsVersion);
  // setting application version for micro application versioning using @elliemae/pui-app-loader
  window.emui = window.emui || {};
  window.emui.version = utils.getVersionForAppLoader();
  setUpLogger();
  await setUpBrum();
  console.log('loading application using loadApplication');
  if (window.location.hostname === 'localhost') {
    console.log('Loading current application in localhost mode');
    return loadDefaultApplication();
  } else {
    console.log('ebs version', ebsVersion);
    try {
      let manifest = session.get('appManifest');
      if (!manifest) {
        manifest = await fetchENCW('/encompass/app-manifest.json', fetchOptions());
        session.set('appManifest', manifest);
      }
      const ebs = ebsVersion.encompassVersion.substring(0, ebsVersion.encompassVersion.lastIndexOf('.'));
      __env.ebsVersion = ebs;
      console.log('Trying to match EBS version : ', manifest, ebs);
      const renderer_version = manifest[ebs]['renderer_version'];
      session.set('UI_VERSION', renderer_version && renderer_version.replace(/.{1}[a-zA-Z]{1,}$/g, ''));

      // manifest['LATEST'] has to be replaced with the release version like 21.4, 22.1 etc. only in the release branches for the particular release.
      if (ebs === (VERSION || manifest['LATEST'])) {
        console.log('ebs version matches latest app supported version');
        return loadDefaultApplication();
      } else if (manifest[ebs]) {
        removeScript('app');
        removeScript('env');
        removeScript('polyfills');
        window.webpackJsonp = undefined;
        if (window.globalShell && !hostAppArgs.dontCallInit) {
          console.log('loading desktop scripts and styles');
          await loadScript(manifest[ebs]['env']['js']);
          await loadScript(manifest[ebs]['app']['js']);
          hostAppArgs.dontCallInit = true;
          return window.parent.emui[appID].init(hostAppArgs);
        } else {
          console.log('loading mobile scripts and styles');
          await loadScript(manifest[ebs]['env']['js']);
          return loadScript(manifest[ebs]['app']['js']);
        }
      } else {
        console.log('No app version found for given EBS version');
        //No app, loading default (i.e latest), need to set correct renderer version to match
        session.set('UI_VERSION', manifest[manifest['LATEST']]['renderer_version']);
        return loadDefaultApplication();
      }
    } catch (e) {
      console.log('Some error in parsing/looking up ebs and app version', e);
      //No app, loading default (i.e latest), need to set correct renderer version to match
      session.set('UI_VERSION', manifest[manifest['LATEST']]['renderer_version']);
      return loadDefaultApplication();
    }
  }
}
function checkDevice() {
  let device = 'web';
  // look at US string to determine if its mobile like or tablet like device...
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|Mobile/i.test(navigator.userAgent)) {
    // look at device width and height to determine if its tablet or mobile...
    // note: device size is static and does not change when the page is re-sized or rotated.
    // note: device.availWidth and device.availHeight will be device size minus UI task bars.
    if (window.screen.width >= 1024 || window.screen.height >= 1024) {
      device = 'tablet';
    } else {
      device = 'mobile';
    }
  }
  /* Temp Solution until Testing team figures out how to test applications */
  let entryPoint = window.location.href;
  let urlDevice = entryPoint.substr(entryPoint.indexOf('=') + 1, entryPoint.length);
  if (urlDevice === 'tablet' || urlDevice === 'mobile' || urlDevice === 'web') {
    device = urlDevice;
    window.sessionStorage.setItem('device', urlDevice);
  } else {
    let localDevice = window.sessionStorage.getItem('device'); // <-- preserve overrides for session to cover page refresh scenario
    if (localDevice === 'tablet' || localDevice === 'mobile' || localDevice === 'web') {
      device = localDevice;
    }
  }
  return device;
}

function loadCSS(href) {
  let ss = window.document.createElement('link');
  let head = window.document.getElementsByTagName('head')[0];

  ss.rel = 'stylesheet';
  ss.href = __env.baseHref + href;

  // temporarily, set media to something non-matching to ensure it'll fetch without blocking render
  ss.media = 'only x';

  head.appendChild(ss);

  setTimeout(() => {
    // set media back to `all` so that the stylesheet applies once it loads
    ss.media = 'all';
  }, 0);
}

function getSessionStorage(key) {
  const value = sessionStorage.getItem(key);
  return (value && JSON.parse(value)) || {};
}

async function checkLogin(device, hostAppArgs = {}) {
  __env.device = device;

  if (__env.device == 'mobile') {
    session.set('loBuild', ___ems_build());
  }

  let userSettings = session.getItem('userSettings');
  const obPopupFlag = session.get('isOBPopupFlag');
  const {
    location: { pathname }
  } = window;

  if (
    __env.device !== 'mobile' &&
    (userSettings.isSuperAdministrator || userSettings.isAdministrator) &&
    ['/pipeline', '/workflow-tasks', '/prospects', '/globalcustomtools', '/fullview'].every(
      route => !pathname.includes(route)
    )
  ) {
    if (obPopupFlag) {
      window && window.top && window.top.postMessage({ Operation: 'OBCallBack' });
    }
    window.location.href = '/admin';
  } else {
    const ebsUrl = `${window.__env['apiHost']}/encompass/v3/system/info`;
    const encompassVersion = sessionStorage.getItem('encompassVersion') || '';
    const ebsVersion = session.get('ebsVersion') || (encompassVersion ? { encompassVersion } : {});

    if (ebsVersion && !isEmpty(ebsVersion)) {
      return await loadApplication(ebsVersion, hostAppArgs);
    }
    return fetchENCW(ebsUrl, fetchOptions())
      .then(response => {
        console.log({ response });
        console.log('response.code: ', response.code);
        if (response && response.code === '100003') {
          console.log('log out');
          return logout({ __env, errorCode: response.code });
        } else {
          console.log('load app');
          return loadApplication(response, hostAppArgs);
        }
      })
      .catch(err => {
        // Load latest version of app available
        console.log('ebs API Error: ', err);
        return loadDefaultApplication();
      });
  }
}

const getPameterId = () => window.parent.location.pathname.split('/').pop();

function changeUrl() {
  const url = `${location.origin}${window.parent.location.pathname}`;
  history.pushState({}, null, url);
}

const init = async args => {
  const { hostUrl, path = '', host, history } = args;
  window.hostApp = { host, history };
  const prefix = 'ngStorage-';
  __webpack_public_path__ = `${hostUrl + path}/`.replace(/([^:]\/)\/+/g, '$1');
  sessionStorage.setItem('pui-container', 'pui-container-encompassweb');
  sessionStorage.setItem(`${prefix}isPipelineReady`, true);
  const device = checkDevice();
  changeUrl();
  await checkLogin(device, args);
};

const appID = 'encompassweb';
window.parent.emui = window.parent.emui || {};
window.parent.emui[appID] = window.parent.emui[appID] || {};
window.parent.emui[appID].init = init.bind(this);
window.parent.emui[appID].postMessage = function(...args) {
  window.postMessage(...args);
};
window.parent.emui[appID].getWindow = function() {
  return window;
  window.parent.emui[appID].unmount = unmount;
};
window.parent.emui[appID].changeUrl = changeUrl;

const localLogin = () => {
  __webpack_public_path__ = __webpack_public_path__ || window.location.protocol + '//' + window.location.host + '/';
  login(__env).then(() => {
    const device = checkDevice();
    return checkLogin(device);
  });
};

if (!window.globalShell) {
  // means its not logged in via micro framework, need to add a seperate flag for that
  localLogin();
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}
